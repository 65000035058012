<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("page_title"),
      titleTemplate: "%s | 「圖說澳門」AR海上遊",
    };
  },
};
</script>
