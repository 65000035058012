<template>
  <div class="fixed inset-0 w-full h-full">
    <div class="flex items-center justify-center w-full h-full relative">
      <img
        :src="target"
        alt=""
        class="mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style="width: 80%"
      />
      <div>
        <p
          class="font-bold text-lg text-center text-white mb-4 -mt-4"
          style="text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px"
        >
          {{ $t("please_aim") }}
        </p>
        <img
          src="/img/scan/scan-frame.png"
          alt=""
          class="mx-auto"
          style="width: 90%"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["target"],
};
</script>
