import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import Collection from '../views/scene-list.vue'
import Scene1 from '../views/scene-1.vue'
import Scene2 from '../views/scene-2.vue'
import Scene3 from '../views/scene-3.vue'
import Scene4 from '../views/scene-4.vue'
import Scene5 from '../views/scene-5.vue'
import Scene6 from '../views/scene-6.vue'
import Scene7 from '../views/scene-7.vue'
import Scene8 from '../views/scene-8.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/collection',
    name: 'collection',
    component: Collection
  },
  {
    path: '/scene-1',
    name: 'scene-1',
    component: Scene1 
  },
  {
    path: '/scene-2',
    name: 'scene-2',
    component: Scene2
  },
  {
    path: '/scene-3',
    name: 'scene-3',
    component: Scene3
  },
  {
    path: '/scene-4',
    name: 'scene-4',
    component: Scene4 
  },
  {
    path: '/scene-5',
    name: 'scene-5',
    component: Scene5 
  },
  {
    path: '/scene-6',
    name: 'scene-6',
    component: Scene6 
  },
  {
    path: '/scene-7',
    name: 'scene-7',
    component: Scene7
  },
  {
    path: '/scene-8',
    name: 'scene-8',
    component: Scene8 
  },
]

const router = new VueRouter({
  routes
})

export default router
