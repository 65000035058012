<template>
  <div
    class="fixed inset-0"
    style="
      background-image: url('/img/bg.jpg');
      background-size: cover;
      background-position: center center;
    "
  >
    <!-- Title -->
    <img
      src="/img/title_tw.png"
      class="mx-auto absolute left-1/2 -translate-x-1/2"
      style="height: 10vh; top: 5vh"
    />

    <!-- Language Buttons -->
    <div class="flex items-center justify-center h-screen">
      <div class="container mx-auto px-4" style="margin-top: -5vh">
        <!-- Choose Language -->
        <div class="text-center font-bold text-black">
          <h2 style="font-size: 2.5vh">請選擇語言</h2>
          <h2 style="font-size: 2.5vh">请选择语言</h2>
          <h2 style="font-size: 2vh">Please chooese your languarge</h2>
        </div>

        <!-- Buttons -->
        <div class="space-y-4" style="margin-top: 2vh">
          <button
            class="bg-black text-white font-bold py-4 px-4 rounded-md block mx-auto w-2/3"
            style="font-size: 2.5vh"
            @click="goCollection('tw')"
          >
            粵語
          </button>
          <button
            class="bg-black text-white font-bold py-4 px-4 rounded-md block mx-auto w-2/3"
            style="font-size: 2.5vh"
            @click="setLocale('cn')"
          >
            普通话
          </button>
          <button
            class="bg-black text-white font-bold py-4 px-4 rounded-md block mx-auto w-2/3"
            style="font-size: 2.5vh"
            @click="setLocale('en')"
          >
            English
          </button>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <img
      src="/img/mgm-logo.png"
      class="mx-auto absolute left-1/2 -translate-x-1/2"
      style="height: 15vh; bottom: 1vh"
    />
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push("/collection");
    },

    goCollection(locale) {
      if (locale === "tw") {
        this.$i18n.locale = locale;
        this.$router.push("/collection");
      }
    },
  },
};
</script>
