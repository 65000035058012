<template>
  <div
    class="fixed inset-0"
    style="
      background-image: url('/img/bg.jpg');
      background-size: cover;
      background-position: center center;
    "
  >
    <!-- Title -->
    <img
      v-if="$i18n.locale === 'tw'"
      src="/img/title_tw.png"
      class="mx-auto absolute top-16 left-1/2 -translate-x-1/2"
      style="height: 10vh"
    />
    <img
      v-else-if="$i18n.locale === 'cn'"
      src="/img/title_cn.png"
      class="mx-auto absolute top-16 left-1/2 -translate-x-1/2"
      style="height: 10vh"
    />
    <img
      v-else
      src="/img/title_en.png"
      class="mx-auto absolute top-16 left-1/2 -translate-x-1/2"
      style="height: 10vh"
    />

    <!-- Scene -->
    <img
      v-if="scene"
      :src="scene"
      class="mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      style="height: 40vh"
    />

    <!-- Loading Footer -->
    <div class="-kivi-loading-footer">
      <div class="progress-box">
        <p class="text-black text-center mb-2 font-medium">
          {{ $t("loading_wait") }}
        </p>

        <!-- Loading Bar -->
        <div
          class="flex w-full h-2 bg-white rounded-full overflow-hidde"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center overflow-hidden rounded-full bg-black text-xs text-white text-center whitespace-nowrap transition duration-500"
            :style="{ width: percent + '%' }"
          />
        </div>
      </div>
      <div class="-kivi-loading-text">{{ percent }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["percent", "scene"],
};
</script>

<style>
.-kivi-loading-layout {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #0a001e;
}

.-kivi-loading-content {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.-kivi-loading-content-img {
  display: block;
  width: 69.87vw;
  height: 44.8vw;
  margin: 0 auto;
}

.-kivi-loading-footer {
  position: absolute;
  width: 70%;
  bottom: 10vw;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
}

.-kivi-loading-icon {
  display: block;
  width: 31.73vw;
  height: 9.07vw;
  margin: 0 auto;
}

.-kivi-loading-text {
  margin-top: 1.05vw;
  text-align: center;
  font-size: 3.2vw;
  line-height: 4.53vw;
  letter-spacing: 2px;
  font-weight: bold;
  color: black;
}
</style>
