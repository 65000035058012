<template>
  <div>
    <div
      class="fixed inset-0"
      style="
        z-index: -1;
        background-image: url('/img/bg.jpg');
        background-size: cover;
        background-position: center center;
      "
    />
    <!-- Back -->
    <router-link
      to="/"
      class="absolute left-3 top-3 w-10 h-10 flex items-center justify-center text-black"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5 8.25 12l7.5-7.5"
        />
      </svg>
    </router-link>

    <div class="container mx-auto px-4 pb-8">
      <!-- Title -->
      <img
        v-if="$i18n.locale === 'tw'"
        src="/img/title_tw.png"
        class="mt-8 w-full"
      />
      <img
        v-if="$i18n.locale === 'cn'"
        src="/img/title_cn.png"
        class="mt-8 w-full"
      />
      <img
        v-if="$i18n.locale === 'en'"
        src="/img/title_eng.png"
        class="mt-8 w-full"
      />

      <div class="grid grid-cols-1 gap-4 my-8 overflow-y-scroll">
        <div v-for="item in items" :key="item.title" class="space-y-2">
          <router-link
            :to="item.url"
            class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
          >
            <div class="p-4">
              <h2 class="font-bold">
                {{ $t(item.title) }}
              </h2>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "MGM鏡海歸帆海上遊",
      items: [
        {
          title: "scene1_title",
          url: "/scene-1",
        },
        {
          title: "scene2_title",
          url: "/scene-2",
        },
        {
          title: "scene3_title",
          url: "/scene-3",
        },
        {
          title: "scene4_title",
          url: "/scene-4",
        },
        {
          title: "scene5_title",
          url: "/scene-5",
        },
        {
          title: "scene6_title",
          url: "/scene-6",
        },
        {
          title: "scene7_title",
          url: "/scene-7",
        },
        {
          title: "scene8_title",
          url: "/scene-8",
        },
      ],
    };
  },
};
</script>
