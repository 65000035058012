/* eslint-disable */
<template>
  <div id="app">
    <!-- Loadding -->
    <div
      class="fixed inset-0 flex items-center justify-center"
      style="
        z-index: -1;
        background-image: url('/img/bg.jpg');
        background-size: cover;
        background-position: center center;
      "
    >
      <svg
        class="animate-spin -ml-1 mr-3 h-10 w-10 text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>

    <iframe
      ref="kivicubeScene"
      v-bind="props"
      @load="load"
      @error="error"
      @ready="ready"
      @downloadAssetStart="downloadAssetStart"
      @downloadAssetProgress="downloadAssetProgress"
      @downloadAssetEnd="downloadAssetEnd"
      @loadSceneStart="loadSceneStart"
      @loadSceneEnd="loadSceneEnd"
      @sceneStart="sceneStart"
      @tracked="tracked"
      @lostTrack="lostTrack"
    />

    <iframe
      ref="kivicubeScene"
      v-bind="props"
      @load="load"
      @error="error"
      @ready="ready"
      @downloadAssetStart="downloadAssetStart"
      @downloadAssetProgress="downloadAssetProgress"
      @downloadAssetEnd="downloadAssetEnd"
      @loadSceneStart="loadSceneStart"
      @loadSceneEnd="loadSceneEnd"
      @sceneStart="sceneStart"
      @tracked="tracked"
      @lostTrack="lostTrack"
    />

    <!-- Close Btn -->
    <div class="inline-block fixed top-4 left-4 z-20" @click="goBackView">
      <img src="/img/close.png" alt="" class="w-8 h-8" />
    </div>

    <!-- Scanning -->
    <scanning-screen v-if="scanning" :target="previewImage" />

    <!-- Loading -->
    <loading-screen v-if="loading" :scene="loadingImage" :percent="progress" />
  </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen.vue";
import ScanningScreen from "@/components/ScanningScreen.vue";
export default {
  components: { LoadingScreen, ScanningScreen },
  metaInfo() {
    return {
      title: this.$t("scene3_title") + " | " + this.$t("page_title"),
    };
  },
  data() {
    return {
      props: {},
      view: null,
      previewImage: "/img/preview/scene-3.png",
      loadingImage: "/img/loading_scene-3.png",
      sceneId:
        this.$i18n.locale === "tw"
          ? "zYU9R5TRHbV9E0TZ78DIxR57Y5AD6Teh"
          : this.$i18n.locale === "cn"
          ? "5m48B4FBne0nGb8MHA3VH2yJLS5hWUFO"
          : "lMCd5RJ1gyEVQ3D1pDzC463Bj88yQ3ck",
      license: "",
      version: "tracking2",
      loading: false,
      progress: 0,
      scanning: false,
      photoing: false,
      showScene: false,
      preview: false,
      model: null,
      audio: null,
    };
  },
  async mounted() {
    // 变量kivicubeIframePlugin来自于kivicube lib：https://www.kivicube.com/lib/iframe-plugin.js
    // index.html之中使用script标签引入。
    // eslint-disable-next-line no-undef
    this.props = kivicubeIframePlugin.openKivicubeScene(
      this.$refs.kivicubeScene,
      {
        sceneId: this.sceneId,
        hideLogo: true,
        hideTitle: true,
        // hideDownload: true,
        hideLoading: true,
        hideScan: true,
        // hideTakePhoto: true,
        hideBackground: false,
      },
      false
    );
  },
  beforeDestroy() {
    window.kivicubeIframePlugin.destroyKivicubeScene(this.$refs.kivicubeScene);
    this.model = null;
    this.view = null;
    this.loading = null;
    if (this.audio) {
      this.audio.destroy();
    }
  },
  methods: {
    load() {
      console.log("load");
    },

    error() {
      console.log("error");
    },

    ready({ detail: view }) {
      console.log("ready", view);
      this.view = view;
      // this.loading = true;
    },

    downloadAssetStart() {
      console.log("downloadAssetStart");
    },

    downloadAssetProgress({ detail }) {
      // console.log("downloadAssetProgress", detail);
      this.progress = (detail * 97) | 0;
    },

    downloadAssetEnd() {
      console.log("downloadAssetEnd");
    },

    loadSceneStart() {
      console.log("loadSceneStart");
    },

    loadSceneEnd() {
      console.log("loadSceneEnd");
      this.progress = 100;
    },

    async sceneStart() {
      console.log("sceneStart");

      this.scanning = true;
      this.loading = false;

      // console.log("locale", this.$i18n.locale);
      // console.log("view", this.view);
      // console.log("getObject", typeof this.view.getObject);
      // console.log("getObjects", typeof this.view.getObjects);

      // if (typeof this.view.getObject === "function") {
      //   console.log(await this.view.getObject("model"));

      //   this.model = await this.view.getObject("model");
      //   if (this.$i18n.locale === "tw") {
      //     this.audio = await this.view.getObject("audio_can");
      //   } else if (this.$i18n.locale === "cn") {
      //     this.audio = await this.view.getObject("audio_man");
      //   } else {
      //     this.audio = await this.view.getObject("audio_eng");
      //   }
      // }
    },

    tracked() {
      this.scanning = false;
      this.photoing = true;

      // this.playModel(this.model, "Animation", false);
      // this.playAudio(this.audio);
    },

    lostTrack() {
      // this.scanning = true;
      this.photoing = false;
    },

    playAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.play();
    },

    pauseAudio(audio) {
      audio.pause();
    },

    stopAudio(audio) {
      audio.stop();
    },

    playbackAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.playback();
    },

    // Control Model
    playModel(model, name, loop) {
      if (!model) return false;
      this.stopModel(model);
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      if (!names.includes(name)) return false;
      model.playAnimation({
        name, // 动画名称
        loop, // 是否循环播放
        clampWhenFinished: true, // 播放完毕后是否停留在动画最后一帧
      });
    },

    stopModel(model) {
      if (!model) return;
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      names.forEach((name) => {
        model.pauseAnimation(name);
      });
    },

    // 拍照相片
    // takePhoto() {
    //   this.preview = true;
    //   this.photoing = false;
    //   console.log("take photo");
    // },
    // takePhoto: async function () {
    //   wx.showLoading({ title: "拍照中...", mask: true });

    //   try {
    //     // this.stop();
    //     const imagePath = await this.view.takePhoto();

    //     this.setData({
    //       previewImage: imagePath,
    //       preview: true
    //     })

    //     wx.hideLoading()
    //     console.log(this.data.previewImage)
    //     return;
    //   } catch (e) {
    //     wx.hideLoading();
    //     console.error(e);
    //     errorHandler(`拍照失敗 - ${e.message}`);
    //   }
    // },

    // 儲存相片
    // async savePhoto(){
    //   const userAuth = await setAuth(
    //       "scope.writePhotosAlbum",
    //       "相册權限被拒絕",
    //       "保存照片需要您授予相册權限"
    //     );
    //   if (!userAuth) {
    //   return wx.showToast({ title: "保存照片失败, 需要相機權限", icon: none });
    //   }
    //   wx.saveImageToPhotosAlbum({
    //       filePath: this.data.previewImage,
    //       success() {
    //       wx.showToast({ title: "保存照片成功", icon: "success" });
    //       },
    //       fail(e) {
    //       console.error(e);
    //       wx.showToast({ title: "保存照片失敗", icon: "error" });
    //       }
    //   })
    // },

    // 關閉相片
    closePhoto() {
      this.previewImage = "";
      this.preview = false;
    },

    goBackView() {
      this.destroyScene();
      // reset status
      this.$nextTick(() => {
        this.scanning = false;
        this.loading = false;
        this.progress = 0;
      });

      this.$router.push("/collection");
    },

    destroyScene() {
      window.kivicubeIframePlugin.destroyKivicubeScene(
        this.$refs.kivicubeScene
      );
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
